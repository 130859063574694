.product-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.artikli-div {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin-top: 32px;
  flex-wrap: wrap;
}

.product-card-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 216px;
  width: 18%;
  min-height: 360px;
  margin-right: 1%;
  margin-left: 1%;
  margin-bottom: 16px;
}

.product-card-img {
  width: 100%;
  height: 216px;
  object-fit: cover;
  object-position: center;
}

.product-card-name {
  font-size: 14px;
  color: var(--blue);
  font-weight: 700;
  text-align: center;
  min-height: 40px;
}

.product-card-id {
  font-size: 10px;
  color: var(--blue);
  font-weight: 400;
  margin: 5px 0px;
}

.product-card-price {
  font-size: 15px;
  color: var(--blue);
  font-weight: 700;
}

.product-card-status-div {
  display: flex;
  align-items: center;
  margin: 5px 0px;
}

.product-card-dot {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.product-card-status-p {
  font-size: 10px;
  font-weight: 500x;
}

.color-green {
  color: var(--green);
}

.color-red {
  color: var(--red);
}

.dot-green {
  background-color: var(--green);
}

.dot-red {
  background-color: var(--red);
}

.product-card-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  width: 100%;
  background-color: var(--blue);
  color: white;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

@media only screen and (max-width: 1200px) {
  .product-card-div {
    margin-right: 2.25%;
    margin-left: 2.25%;
  }
}

@media only screen and (max-width: 756px) {
  .product-card-div {
    min-width: 48%;
    width: 48%;
    margin-right: 1%;
    margin-left: 1%;
  }
}

@media only screen and (max-width: 678px) {
  .cart-heading-div {
    margin-top: 16px;
  }
}
