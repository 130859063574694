.not-found-img {
  max-width: 95vw;
  margin-top: 32px;
}

.not-found-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  background-color: var(--blue);
  border-radius: 10px;
  cursor: pointer;
  color: white;
}
