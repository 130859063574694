.product-page-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 32px;
  width: 100%;
  max-width: 1200px;
}

.product-content-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.product-page-img {
  display: flex;
  width: 50%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.product-text-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-left: 50px;
  width: 50%;
}

.product-page-h {
  font-size: 30px;
  color: var(--blue);
  font-weight: 700;
  text-align: start;
}

.product-brand-div {
  display: flex;
  align-items: center;
  margin: 16px 0px;
}

.product-brand-p {
  font-size: 15px;
  color: var(--darkGray);
  font-weight: 700;
}

.product-brand-span {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  color: var(--blue);
  margin-left: 10px;
}

.product-brand-line {
  display: flex;
  width: 1px;
  height: 15px;
  background-color: var(--blue);
  margin: 0px 15px;
}

.product-page-price {
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  color: var(--blue);
}

.product-dot {
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 10px;
}

.product-dostupnost-p {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}

.color-green {
  color: var(--green);
}

.color-red {
  color: var(--red);
}

.product-opis-div {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  margin-top: 16px;
}

.product-opis-h {
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  color: var(--darkGray);
}

.product-opis-line {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: var(--darkGray);
  margin-top: 10px;
  margin-bottom: 30px;
}

.product-opis-p {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: var(--darkGray);
}

@media only screen and (max-width: 1200px) {
  .product-page-content {
    width: 100%;
    max-width: 95vw;
    margin-top: 8px;
  }

  .product-content-div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
  }

  .product-page-img {
    display: flex;
    width: 100%;
    height: auto;
  }

  .product-text-div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-left: 0px;
    width: 100%;
  }

  .product-page-h {
    text-align: center;
  }
}
