@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "animate.css";

html,
body,
h1,
h2,
h3,
p {
  padding: 0px;
  margin: 0px;
}

body {
  font-family: "Poppins", sans-serif;
}

div,
body,
html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

div::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}

input:focus {
  outline: none;
}

:root {
  --blue: #031623;
  --red: #e20000;
  --green: #00bc35;
  --gray: #d9d9d9;
  --darkGray: #808080;
}
