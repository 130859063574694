.page-container {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.brands-div {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1200px;
  margin-top: 32px;
}

.brand-div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 380px;
  max-width: 380px;
  height: 146px;
  border-radius: 20px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 16px;
  cursor: pointer;
}

.brand-img {
  max-width: 280px;
  max-height: 100px;
  object-fit: contain;
}

@media only screen and (max-width: 500px) {
  .brand-div {
    min-width: 95vw;
    max-width: 95vw;
  }
}
