.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 73px;
  background-color: var(--blue);
}

.header-logo {
  width: 91px;
  height: 58px;
}
